.modalImage {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
  filter: brightness(0.7) contrast(0.7);
}

.modalText {
  margin: 16px;
}
