.introContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: JosefinSans;
  flex: 1;
  animation: appear 0.5s ease-in-out;
  transform-origin: 50% 50%;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: scale(0.8) translate(0px, 100px);
  }
  to {
    opacity: 1;
    transform: scale(1) translate(0px, 0px);
  }
}

.profilePic {
  width: 16em;
  height: 16em;
  object-fit: cover;
}

.nameTitle {
  font-size: 4rem;
  margin-top: 32px;
  font-weight: 100;
  line-height: 1;
}

.nameSubtitle {
  font-weight: 300;
}

@media (max-width: 576px) {
  .nameTitle {
    font-size: 10vw;
  }
  .profilePic {
    width: 128px;
    height: 128px;
  }
  .introContainer {
    padding: 8px 0;
  }
}
