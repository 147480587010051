@font-face {
  font-family: IMBPlexMono;
  src: url(./assets/IBMPlexMono.ttf);
}

@font-face {
  font-family: JosefinSans;
  src: url(./assets/JosefinSans.ttf);
}

html {
  overflow: hidden;
}

#root {
  overflow: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.portfolio-content {
  height: 100vh;
}

.portfolio-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  scroll-snap-align: start;
  padding-top: 70px;
}

.portfolio-section-header {
  display: flex;
  align-items: center;
  min-height: 70px;
}

.portfolio-section-header > span {
  font-family: JosefinSans;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 28px;
  margin-right: 8px;
}

.scrollDownLottie {
  position: absolute;
  filter: brightness(99);
  height: 128px;
  width: 128px;
  bottom: 0;
  margin-left: -64px;
  left: 50%;
}
