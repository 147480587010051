.windowContainer {
  border-radius: 8px;
  height: 480px;
  width: 100%;
  background-color: #212121;
  overflow: hidden;
  margin: 16px 0;
  flex: 1;
  /*max-height: 900px; */
}

.windowHeader {
  height: 28px;
  background-color: #5e5e5e;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.windowHeader > div {
  height: 14px;
  width: 14px;
  border-radius: 100%;
  margin-left: 8px;
}
