.gridItem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  filter: saturate(0.2);
  aspect-ratio: 1 / 1;
  border: 0;
}

.gridItemOverlay {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
  z-index: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: opacity 0.3s;
}

.gridItemImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  transition: all 0.3s;
  transform: scale(1.1);
}

.gridItemTitle {
  color: white;
  font-family: JosefinSans;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 20px;
  z-index: 1;
  transition: all 0.3s;
}

.gridItem:hover {
  filter: saturate(1);
}

.gridItem:hover > .gridItemOverlay {
  opacity: 0.7;
}
.gridItem:hover > .gridItemTitle {
  transform: scale(1.1);
  font-weight: 400;
}

.gridItem:hover > .gridItemImage {
  transform: scale(1);
}

@media (max-width: 768px) {
  .gridItem {
    flex-basis: 30%;
  }
}

@media (max-width: 375px) {
  .gridItem {
    flex-basis: 50%;
  }
}
